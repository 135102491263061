import React, { useEffect } from "react";
import { AppWrapper } from "../components/AppWrapper";
import { BackgroundSection } from "../components/Background/BackgroundSection";
import Footer from "../components/ui/Footer";
import { Header } from "../components/Header";
import { SeFormerHypnose } from "../components/SeFormerHypnose/SeFormerHypnose";
import bg from "../components/SeFormerHypnose/bg.webp";
import { SVGWave3 } from "../components/Background/SVGWave3";
import { WidthContainer } from "../components/WidthContainer";
import { useMobile } from "../hooks/hooks.js";
import firebase from "gatsby-plugin-firebase";
import { ANALYTIC_EVENT_NAMES } from "../constants/analytics";
import {useTranslation} from "gatsby-plugin-react-i18next";
import {graphql} from "gatsby";

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
export default function IndexPage({ location }) {
  const { mobile } = useMobile();
  const { t } = useTranslation();
  // analytics - page viewed event
  useEffect(() => {
    if (!firebase) {
      return;
    }
    firebase.analytics().logEvent(ANALYTIC_EVENT_NAMES.web_page_viewed);
    firebase.analytics().logEvent(ANALYTIC_EVENT_NAMES.web_hypnosis_training_page_viewed);
  }, [firebase]);

  return (
      <AppWrapper>
        <Header {...{ location: location.pathname }} />
        <div {...{
          style: {
            background: `url(${bg})`,
            backgroundSize: "cover",
            color: "white",
            // margin: "36px 0"
          },
        }}>
          <WidthContainer center>
            <div id="" className="" {...{
              style: {
                width: mobile ? "" : "66%"
              },
            }}>
              <br />
              <br />
              <br />
              <br />
              <br />
              <div id="" className="" {...{ style: { fontSize: "36px", fontWeight: "700" }, }}>
                {t('hypnosis_training.header')}
              </div>
              <div id="" className="" {...{ style: { fontSize: "24px", fontWeight: "400" }, }}>
                {t('hypnosis_training.description')}
              </div>
              <br />
              <br />
              <br />
            </div>
          </WidthContainer>
          <SVGWave3 style={{
            gridArea: "a",
            placeSelf: "end",
          }} {...{
            fill: "#CFE0EE",
          }} />
        </div>
        <BackgroundSection hypnose>
          <SeFormerHypnose />
          {/* <FormContact /> */}
        </BackgroundSection>
        <Footer />
      </AppWrapper>
  );
}
