import * as React from "react"



const SVGWave3 = ({ fill = "#83A5D5", style }) => {
    const p1 = { x: 0, y: 20 }
    const c1 = { x: 200, y: -45 }
    const c2 = { x: 300, y: 75 }
    const p2 = { x: 500, y: 75 }
    const p3 = { x: 500, y: 100 } // close path
    const p4 = { x: 0, y: 100 } // close path
    return (
        <svg
            // height="100%"
            width="100%"
            viewBox="0 0 500 75" xmlns="http://www.w3.org/2000/svg"
            fontSize="6px"
            {...{ style }}
        >
            <path
                d={`
                    M ${p1.x} ${p1.y} 
                    C ${c1.x} ${c1.y} ${c2.x} ${c2.y} 
                    ${p2.x} ${p2.y}
                    L ${p3.x} ${p3.y}
                    ${p4.x} ${p4.y}
                    `}
                fill={fill}
            />





        </svg>
    )
}

export { SVGWave3 }