
import * as React from 'react';
import { useMobile } from '../../hooks/hooks';
import formeDown from "../Background/forme-down.png";
import formeTransfersal from "../Background/forme-transversal.png";
import formeUp from "../Background/forme-up.png";
import { SVGWaveFooter } from './SVGWaveFooter';
import { SVGWave3 } from './SVGWave3';

const BackgroundSection = ({
    children,
    bottomBenefits,
    themeTemoignages,
    pricing,
    style,
    equipe,
    entreprise,
    ambassadeurs,
    hypnose,
    footer,
}: {
    children: React.ReactNode,
    bottomBenefits?: boolean,
    themeTemoignages?: boolean,
    pricing?: boolean,
    equipe?: boolean,
    entreprise?: boolean,
    footer?: boolean,
    ambassadeurs?: boolean,
    hypnose?: boolean,
    style?: React.CSSProperties,
}) => {
    const { mobile } = useMobile()
    return (
        <div
            id="BackgroundSection"
            className=""
            {...{
                style: {
                    display: "grid",
                    width: "100vw",
                    zIndex: -5,
                    overflow: "hidden",
                    ...style
                },
            }}
        >
            {bottomBenefits &&
                <img src={formeUp} alt="" style={{
                    // gridArea: "7/1/-1/2",
                    gridArea: "1/1/-1/-1",
                    placeSelf: "end start",
                    maxWidth: "20%",
                }} />
            }
            {themeTemoignages &&
                <img src={formeDown} alt="" style={{
                    gridArea: "1/1/-1/-1",
                    placeSelf: "center end",
                    maxWidth: "25%",
                    marginRight: "-10%",
                    marginBottom: "0vh"
                }} />
            }
            {pricing &&
                <>
                    <img src={formeTransfersal} alt="" style={{
                        gridArea: "1/1/-1/-1",
                        placeSelf: "end center",
                        objectFit: "contain",
                    }} />

                </>
            }
            {equipe &&
                <div   {...{
                    style: {
                        gridArea: "1/1/-1/-1",
                        zIndex: 10,
                        overflow: "hidden",
                        display: "grid",
                        gridTemplate: `
                                "a" auto
                                "b" 1fr
                            `,
                    },
                }} >
                    <SVGWave3 style={{
                        gridArea: "a",
                        placeSelf: "end",
                        // zIndex: -10,
                    }} />
                    <div   {...{
                        style: {
                            gridArea: "b",
                            background: "linear-gradient(#83A5D5 0%, #7998C3)",
                            display: "grid",
                            gridTemplate: `
                                ""
                            `,
                            placeItems: "end"
                        },
                    }}
                    >

                        <SVGWaveFooter />
                    </div>
                </div>
            }
            {entreprise &&
                <div   {...{
                    style: {
                        gridArea: "1/1/-1/-1",
                        zIndex: 10,
                        overflow: "hidden",
                        display: "grid",
                        gridTemplate: mobile ?
                            `      
                            "a" 
                            "b" 60fr
                        `:
                            `
                                "a" 20fr
                                "b" 60fr
                            `,
                        background: "#A7BCDB"
                    },
                }} >
                    <SVGWave3 style={{
                        gridArea: "a",
                        placeSelf: "end",
                    }} {...{
                        fill: "#CFE0EE",
                    }} />
                    <div   {...{
                        style: {
                            gridArea: "b",
                            background: "linear-gradient(#CFE0EE 0%, #ADC7E6)",
                            display: "grid",
                            gridTemplate: `
                                ""
                            `,
                            placeItems: "end"
                        },
                    }}
                    >
                        <SVGWaveFooter />
                    </div>
                </div>
            }
            {hypnose &&
                <div   {...{
                    style: {
                        gridArea: "1/1/-1/-1",
                        zIndex: 10,
                        overflow: "hidden",
                        display: "grid",
                        gridTemplate: mobile ?
                            `      
                            "b" 60fr
                        `:
                            `
                                "b" 60fr
                            `,
                        background: "#A7BCDB"
                    },
                }} >
                    <div   {...{
                        style: {
                            gridArea: "b",
                            background: "linear-gradient(#CFE0EE 0%, #ADC7E6)",
                            display: "grid",
                            gridTemplate: `
                                ""
                            `,
                            placeItems: "end",
                            // paddingBottom: "200px"
                        },
                    }}
                    >
                        <SVGWaveFooter />
                    </div>
                </div>
            }
            {ambassadeurs &&
                <div   {...{
                    style: {
                        gridArea: "1/1/-1/-1",
                        zIndex: 10,
                        display: "grid",
                        gridTemplate: mobile ?
                            `      
                            "a" 20fr
                            "b" 100fr
                        `:
                            `
                                "a" 1fr
                                "b" 2fr
                            `,
                        background: "#A7BCDB",
                        overflow: "hidden"
                    },
                }} >
                    <SVGWave3 style={{
                        gridArea: "a",
                        placeSelf: "end",
                    }} {...{
                        // fill: "#2E8585",
                        fill: "#F5FAFA",
                    }} />
                    <div   {...{
                        style: {
                            gridArea: "b",
                            background: "linear-gradient(#F5FAFA 0%, #C9C6C2)",
                            display: "grid",
                            gridTemplate: `
                                ""
                            `,
                            placeItems: "end"
                        },
                    }}
                    >

                        <SVGWaveFooter />
                    </div>
                </div>
            }

            <div id=""
                className=""
                {...{
                    style: {
                        gridArea: "1/1/-1/-1",
                        display: "grid",
                        zIndex: 10,
                        placeSelf: "center"
                    },
                }}>

                {children}
            </div>
        </div >
    )
}

export { BackgroundSection };
