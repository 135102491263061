import React from 'react';
import img1 from "./journaldesfemmes-e1645187334139.webp"
import img2 from "./xavier-clausse-e1645187363183.jpeg"
import { useMobile } from "../../hooks/hooks.js"
import { WidthContainer } from '../WidthContainer';
import {useTranslation} from "gatsby-plugin-react-i18next";

const SeFormerHypnose = ({ }) => {
    const { mobile, tablet, medium } = useMobile()
    const { t } = useTranslation();
    return (
        <WidthContainer center>

            <div id="" className="" {...{
                style: {
                    // height: "600px",
                    display: "grid",
                    gridTemplate: medium ? `
              "a"
              "b"
              `: `
            "a b"
          `,
                    gap: "24px",
                    margin: "40px 0 300px 0",
                    placeContent: "center",
                },
            }}>
                <a href='https://www.hypnoteam.org/#Formations' id="" className="" {...{ style: {}, }}>

                    <div id="" className="" {...{ style: { fontSize: "36px", fontWeight: "700" }, }}>
                        {t('hypnosis_training.hypnosis_training_book')}
                    </div>
                    <div id="" className="" {...{ style: { fontSize: "24px", fontWeight: "400" }, }}>
                        {t('hypnosis_training.hypnosis_training_book_sub_title')}
                    </div>
                    <img src={img1} alt="" style={{
                        borderRadius: "40px", height: "160px", marginTop: "24px",
                        objectFit: "contain"
                    }} />
                </a>
                <a href='https://www.amazon.fr/secrets-lhypnose-spectacle-Xavier-Clausse/dp/B08SB6QNTC/ref=asc_df_B08SB6QNTC/?tag=googshopfr-21&linkCode=df0&hvadid=454810965752&hvpos=&hvnetw=g&hvrand=16555432931852778865&hvpone=&hvptwo=&hvqmt=&hvdev=c&hvdvcmdl=&hvlocint=&hvlocphy=9056140&hvtargid=pla-1127263369081&psc=1' id="" className="" {...{ style: {}, }}>

                    <div id="" className="" {...{ style: { fontSize: "36px", fontWeight: "700" }, }}>
                        {t('hypnosis_training.xavier_clausse_book')}
                    </div>
                    <div id="" className="" {...{ style: { fontSize: "24px", fontWeight: "400" }, }}>
                        {t('hypnosis_training.xavier_clausse_book_sub_title')}
                    </div>
                    <img src={img2} alt="" style={{
                        borderRadius: "40px", height: "160px", marginTop: "24px",
                        objectFit: "contain"
                    }} />
                </a>
            </div>
        </WidthContainer>

    )
}

export { SeFormerHypnose }
